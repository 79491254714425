// Our main CSS
import path from 'path'
import '../css/app.css'
import './SiteMenu.js'

// FAQ Accordion
const accordionItems = document.querySelectorAll('.faq-accordion-item')

if (accordionItems.length > 0) {
  accordionItems.forEach((item) => {
    item.addEventListener('click', () => {
      const content = item.querySelector('.faq-accordion-content')
      item.classList.toggle('faq-accordion-item-active')

      if (item.classList.contains('faq-accordion-item-active')) {
        content.style.maxHeight = content.scrollHeight + 16 + 'px'
        content.style.paddingBottom = '16px'
      } else {
        content.style.maxHeight = 0
        content.style.paddingBottom = 0
      }
    })
  })
}

const siteLanguages = [
  {
    lang: 'en',
    path: '/en'
  },
  {
    lang: 'sv',
    path: '/'
  },
  {
    lang: 'no',
    path: '/no'
  }
]

// User lang settings based on browser language
window.addEventListener('load', () => {
  const locationPathname = window.location.pathname.split('/')[1]
  const langPathname =
    locationPathname === 'no' || locationPathname === 'en' ? locationPathname : 'sv'
  const browserLang = window.navigator.language.split('-')[0]
  const isLangIncluded = siteLanguages.some((language) => language.lang === browserLang)
  const storageLang = localStorage.getItem('userLang')

  /**
   * If the browser has no lang setting, and there is no lang in storage
   * OR
   * if the browser lang is not included on the site, and there is no lang in storage
   * redirect to /en
   */
  if ((!window.navigator.language && !storageLang) || (!isLangIncluded && !storageLang)) {
    if (langPathname == 'en') return

    window.location.replace(`${window.location.origin}/en`)
  }

  /**
   * If the browser has lang setting, the browser lang is supported on site and there is no lang in storage
   * redirect to /browserLang
   * */

  if (isLangIncluded && window.navigator.language && !storageLang) {
    const siteLang = siteLanguages.find((lang) => lang.lang === browserLang)

    if (siteLang && langPathname !== siteLang.lang) {
      const newPath = siteLang.lang === 'sv' ? '/' : siteLang.path
      window.location.replace(`${window.location.origin}${newPath}`)
      return
    }
  }

  /**
   * If there is lang saved in storage, redirect to /storageLang
   */

  if (storageLang) {
    const pathLang = storageLang == 'sv' ? '' : storageLang

    if (langPathname === storageLang) return

    window.location.replace(`${window.location.origin}/${pathLang}`)
  }
})
